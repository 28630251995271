/**
 * コンタクトミー
 */
'use strict';

$(function() {

    // 「質問する」ボタン押下
    $(document).on('click', '.open_contact_window', function() {
        if (is_logined()) {
            $('#contact_me_box').css('display', 'block');
            $('#contact_me_overlay').css('display', 'block');
        } else {
            location.href = '/login/?ref=' + encodeURIComponent(location.href);
            return false;
        }
        $('html,body').animate({scrollTop:0},'slow');
        return false;
    });

    // メッセージ送信ボタン押下
    $('#message_post').click(function() {
        var message_content = $('#message_content').val();
        if (!message_content) {
            $('#message_error').text('メッセージが未入力です。')
                               .css('display', 'block');
            return false;
        } else {
            $('#message_error').css('display', 'none');
            $('#contact_me_box').addClass('contact_me_loadingBase');
            $.ajax({
                type: 'POST',
                cache: false,
                url: '/api/message/send/',
                data: {
                    'project_id': $('#project_id').val(),
                    'body': message_content,
                    'fuel_csrf_token': fuel_csrf_token()
                },
                dataType : 'json',
                success: function( res ) {
                    if (!res.error) {
                    // 送信成功
                        $('#url_confirm').attr('href', res.url_confirm);
                        $('#contact_me_box').css('display', 'none');
                        $('#contact_me_box_confirm').css('display', 'block');
                    } else {
                    // エラー
                        $('#message_error').text(res.error)
                                           .css('display', 'block');
                    }
                    $('#contact_me_box').removeClass('contact_me_loadingBase');
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    // alert(textStatus);
                    $('#contact_me_box').removeClass('contact_me_loadingBase');
                }
            });
        }
    });

    // ログインチェック
    function is_logined() {
        var result = false;
        $.ajax({
            type: 'GET',
            async: false,
            cache: false,
            url: '/api/userstatus/is_logined',
            dataType : 'json',
            success: function( res ) {
                result = res.is_logined;
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                // alert(textStatus);
            }
        });
        return result;
    }

    // 指定のgetパラメータ取得
    function getParam(key) {
        var url = window.location.href;
        var num = url.indexOf('?');
        var queryString = url.slice(num +1);
        var params = queryString.split('&');
        var value = '';

        for(var i=0;i<params.length;i++) {
            var param = params[i].split('=');
            if (param[0] == key) {
                value = param[1];
                break;
            }
        }
        return value;
    }

    // モーダルを閉じる
    $('.contact_me_modal_close, #contact_me_overlay').click(function() {
        $('#contact_me_box').css('display', 'none');
        $('#contact_me_box_confirm').css('display', 'none');
        $('#contact_me_overlay').css('display', 'none');
    });

    // 「質問する」の途中にログイン・新規登録をして戻ってきたときの処理
    var param = getParam('page_mode');
    if (param == 'contact_me') {
        $('#contact_me').trigger('click');
    }

    // 入力文字数チェック
    var max_length = $('#message_content').attr('maxlength');
    // 初期処理
    $(document).ready( function() {
        $('#counter').text(max_length - $('#message_content').val().replace(/\n/g, '  ').length);
    } );
    // イベント処理
    $('#message_content').on('keyup', function(){
        var count = max_length - $(this).val().replace(/\n/g, '  ').length;
        if (count < 0) { count = 0;}
        $('#counter').text(count);
    });
});
